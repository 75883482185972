import request from '@/utils/request'
// 获取服务商列表
export function getSpIdList(data) {
  return request('post', '/apa/sp/getLoginSpList', data)
}

// 用户登录(获取Token)
export function getToken(data) {
  return request('post', '/apa/login', data)
}

// 用户登出
export function getLogout(data) {
  return request('post', '/apa/logout', data)
}

// 用户列表
export function getUserList(data) {
  return request('post', '/apa/user/findUserList', data)
}

// 新增和编辑用户
export function getUserEdit(data) {
  return request('post', '/apa/user/mod', data)
}

// 删除用户
export function getUserDel(data) {
  return request('post', '/apa/user/del', data)
}

// 删除用户
export function getMyselfInfo() {
  return request('get', '/apa/user/userInfo')
}

// 可绑定商户列表
export function getMerchantList() {
  return request('get', '/aps/merchant/mrListBySp')
}

// 绑定商户回显
export function getMerchantRload(data) {
  return request('post', '/aps/user/getUserBindMerchant', data)
}

// 绑定商户
export function getMerchantEdit(data) {
  return request('post', '/aps/user/bindMerchant', data)
}

// 用户列表【带绑定商户】
export function getMerchantUserList(data) {
  return request('post', '/aps/user/merchantUserList', data)
}